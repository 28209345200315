import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ServiceAccountsComponent } from '../control-center/service-accounts/service-accounts.component';
import { AnalyticsComponent } from '../customer/analytics/analytics.component';
import { BusinessSettingsComponent } from '../customer/business-settings/business-settings.component';
import { CampaignManagerComponent } from '../customer/campaign-manager/campaign-manager.component';
import { CampaignListComponent } from '../customer/campaign-manager/campaignlist/campaignlist.component';
import { CampaignsetupComponent } from '../customer/campaign-manager/campaignsetup/campaignsetup.component';
import { CampaignViewComponent } from '../customer/campaign-manager/campaignview/campaignview.component';
import { ConsentManagementComponent } from '../customer/consent-management/consent-management.component';
import { CustomerComponent } from '../customer/customer.component';
import { MediaDriveComponent } from '../customer/media-drive/media-drive.component';
import { MyProfileComponent } from '../customer/my-profile/my-profile.component';
import { MyprofileEditComponent } from '../customer/my-profile/myprofile-edit/myprofile-edit.component';
import { CreateTemplateComponent } from '../customer/reports/create-template/create-template.component';
import { ReportCreateComponent } from '../customer/reports/report-create/report-create.component';
import { ReportsComponent } from '../customer/reports/reports.component';
import { ViewReportsComponent } from '../customer/reports/view-reports/view-reports.component';
import { SupportComponent } from '../customer/support/support.component';
import { TemplatelistComponent } from '../customer/templates/templatelist/templatelist.component';
import { TemplatesComponent } from '../customer/templates/templates.component';
import { TemplatesetupComponent } from '../customer/templates/templatesetup/templatesetup.component';
import { WhatsappPreviewComponent } from '../customer/whatsapp-preview/whatsapp-preview.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { PagenotfoundComponent } from '../pagenotfound/pagenotfound.component';
import { AuthGuard } from '../services/auth.guard';
import { PermissionsGuard } from '../services/permissions.guard';
import { SaBspComponent } from './sa-bsp/sa-bsp.component';
import { SaChannelsComponent } from './sa-channels/sa-channels.component';
import { SaDashboardComponent } from './sa-dashboard/sa-dashboard.component';
import { SaProfileManagementComponent } from './sa-profile-management/sa-profile-management.component';
import { SaReportsComponent } from './sa-reports/sa-reports.component';
import { SaRequestsComponent } from './sa-requests/sa-requests.component';
import { SaRoleManagementComponent } from './sa-role-management/sa-role-management.component';
import { SaSettingsComponent } from './sa-settings/sa-settings.component';
import { SaSupportComponent } from './sa-support/sa-support.component';
import { SuperAdminComponent } from './super-admin.component';

let routes: Routes;
if (environment.sso) {
  routes = [
    {
      path: '',
      component: CustomerComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: ':id/dashboard',
          component: ServiceAccountsComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/analytics',
          component: AnalyticsComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/campaign-manager',
          component: CampaignManagerComponent,
          children: [
            { path: '', component: CampaignListComponent },
            { path: 'create', component: CampaignsetupComponent },
            { path: ':id/edit', component: CampaignsetupComponent },
            { path: ':id/view', component: CampaignViewComponent },
          ],
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/templates',
          component: TemplatesComponent,
          children: [
            { path: '', component: TemplatelistComponent },
            { path: 'create', component: TemplatesetupComponent },
            { path: ':id/edit', component: TemplatesetupComponent },
          ],
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/consent-management',
          component: ConsentManagementComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/reports',
          component: ReportsComponent,
          children: [
            { path: '', component: ViewReportsComponent },
            { path: 'create', component: ReportCreateComponent },
            { path: ':id/edit', component: ReportCreateComponent },
            { path: 'export/:name', component: ReportCreateComponent },
            { path: 'create-template', component: CreateTemplateComponent },
            { path: 'clone/:name', component: CreateTemplateComponent },
          ],
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/media',
          component: MediaDriveComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/support',
          component: SupportComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/business-settings',
          component: BusinessSettingsComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/datepicker',
          component: DatepickerComponent,
        },
        {
          path: ':id/whatsapp',
          component: WhatsappPreviewComponent,
        },
        {
          path: ':id/my-profile',
          component: MyProfileComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/my-profile/edit',
          component: MyprofileEditComponent,
          canActivate: [PermissionsGuard],
        },
        {
          path: ':id/my-profile/edit/pswd',
          component: MyprofileEditComponent,
          canActivate: [PermissionsGuard],
        },
      ],
    },
  ];
} else {
  routes = [
    {
      path: '',
      component: SuperAdminComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: ':id/dashboard',
          component: SaDashboardComponent,
        },
        {
          path: ':id/bsp',
          component: SaBspComponent,
        },
        {
          path: ':id/channels',
          component: SaChannelsComponent,
        },
        {
          path: ':id/requests',
          component: SaRequestsComponent,
        },
        {
          path: ':id/reports',
          component: SaReportsComponent,
        },
        {
          path: ':id/profiles',
          component: SaProfileManagementComponent,
        },
        {
          path: ':id/roles',
          component: SaRoleManagementComponent,
        },
        {
          path: ':id/support',
          component: SaSupportComponent,
        },
        {
          path: ':id/settings',
          component: SaSettingsComponent,
        },
        { path: 'page-not-found', component: PagenotfoundComponent },
        {
          path: '**',
          redirectTo: 'page-not-found',
        },
      ],
    },
  ];
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
